import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosMenu } from "react-icons/io";

const AdminSidebar: React.FC = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <button className="sidebar-toggle" onClick={handleToggleOpen}>
                    <IoIosMenu size="30" />
                </button>
            </div>
            <div className={`sidebar-content ${isOpen ? 'open': ''}`}>
                <div className="sidebar-block">
                    <h3>予約管理</h3>
                    <ul className="sidebar-list">
                        <li><Link to="/admin/reserved">予約一覧</Link></li>
                        <li><Link to="/admin/reserve/add">予約登録</Link></li>
                        <li><Link to="/admin/reserve/stop">予約受付停止設定</Link></li>
                    </ul>
                </div>
                <div className="sidebar-block">
                    <h3>プラン管理</h3>
                    <ul className="sidebar-list">
                        <li><Link to="/admin/plan/1">プラン名変更</Link></li>
                    </ul>
                </div>
                <div className="sidebar-block">
                    <h3>管理者情報</h3>
                    <ul className="sidebar-list">
                        <li><Link to="/admin/users">一覧</Link></li>
                        <li><Link to="/admin/user/add">追加</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default AdminSidebar;
